import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { documentToReactComponents as mapRichText } from '@contentful/rich-text-react-renderer'
import _get from 'lodash.get'

import Hero from '../components/Hero'
import Typography from '../components/Typography'
import PackageProvider from '../components/PackageProvider'
import determineSection from '../utils/determineSection'
import { richTextLegalMap, richTextHeroMap } from '../utils/richTextBlockMap'

import Layout from '../layouts/index'
import BusinessForm from '../components/BusinessForm'

const Business = ({ data }) => {
  const { seoTitle, seoDescription, hero, sections } = data.contentfulPage
  const { siteUrl, brandToken } = data.site.siteMetadata
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en-US' }}>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </Helmet>
      {hero && (
        <Hero
          type={hero.type}
          image={{
            childImageSharp: _get(hero, 'image.localFile.childImageSharp'),
            description: _get(hero, 'image.description'),
            fields: _get(hero, 'image.localFile.fields'),
          }}
          darkText={hero.textColor === 'Dark'}
        >
          <Typography variant="h1">{hero.heading}</Typography>
          {hero.subheading && (
            <Typography variant="h4" rendersAs="h2">
              {hero.subheading}
            </Typography>
          )}
          {hero.module &&
            (hero.module.__typename === 'ContentfulPackage' && (
              <PackageProvider
                price={{
                  amount: hero.module.price,
                }}
                legal={
                  hero.module.disclaimer &&
                  mapRichText(
                    hero.module.disclaimer.mainContent.json,
                    richTextLegalMap
                  )
                }
                bullets={mapRichText(hero.module.bullets.json, richTextHeroMap)}
              />
            ))}
          {hero.mainContent &&
            mapRichText(hero.mainContent.json, richTextHeroMap)}
          <BusinessForm domain={siteUrl} brandToken={brandToken} />
        </Hero>
      )}
      <main>
        {sections && sections.map(section => determineSection(section))}
      </main>
    </Layout>
  )
}

Business.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default Business

export const query = graphql`
  query BusinessPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      siteMetadata {
        siteUrl
        brandToken
      }
    }
  }
`
