import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import styled from '@emotion/styled'
import LeadForm from '../LeadForm'
import { Form } from '../LeadForm/LeadForm.styles'

import businessFormDataJSON from './businessFormData.json'

//load local JSON for setup
import configJSON from '../ConfigForForms/config.json'

//const response =  fetch('./businessForm.txt');
//const businessFormHTML = response.text();
//console.log(businessFormHTML);


const businessFormHTML =  "<div id='theForm'> <link rel='stylesheet' href='https://jereverse-proxytest-fordeletion.azurewebsites.net/jeQuote.css'> <script async='' src='https://www.googletagmanager.com/gtm.js?id=GTM-56PKXFQ'></script> <link rel='stylesheet' href='https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css'> <link rel='stylesheet' href='https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css'> <link rel='stylesheet' href='https://unpkg.com/formiojs@latest/dist/formio.full.min.css'> <script src='https://unpkg.com/formiojs@latest/dist/formio.full.min.js'></script> <script type='text/javascript'> window.onload=function(){Formio.icons='fontawesome'; Formio.createForm(document.getElementById('formio'), 'https://kklxzuvlzuvgtlq.form.io/jequote?_ga=2.171101479.684544087.1590105815-1158075197.1588294505');}; </script> <div id='formio' class='css-1l98hjp' ></div></div>";

const StyledForm = styled(Form)`
  grid-template-columns: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    > div {
      &:nth-of-type(7n + 0),
      &:nth-of-type(8n + 0) {
        display: flex;
        flex-direction: column;
        grid-column: 1 / 3;
      }
    }
  }
`
/*formIOUrl="https://forms-qa.testjustenergy.com/justenergy/jeQuote/jeQuote.js"
    formIOCSSUrl = "https://forms-qa.testjustenergy.com/justenergy/jeQuote/jeQuote.css" */
const BusinessForm = ({ domain, brandToken }) => (
  <LeadForm
    formGUID={config.businessFormGUID}
    labelType="placeholder"
    domain={domain}
    brandToken={brandToken}
    successMessage="Thank you! Your request for a quote has been submitted!"
    errorMessage="We're sorry, something went wrong with our form. Please try again later."
    as={StyledForm}
    inlineFormData={JSON.stringify(businessFormDataJSON)} 
    formIOUrl={configJSON.businessFormHTML}
    formIOCSSUrl = {configJSON.businessFormCSS}
  />
)

//https://jereverse-proxytest-fordeletion.azurewebsites.net/jeAlbertaEBill.js  inlineFormData={JSON.stringify(businessFormDataJSON)} //
// inlineFormData={businessFormHTML}
BusinessForm.propTypes = {
  domain: PropTypes.string.isRequired,
  brandToken: PropTypes.string.isRequired,
}

export default BusinessForm
